import { TState } from 'redux/store'

import { userData } from './types/stateTypes'

export const manualScoringSettingsSelector = (state: TState) =>
  state.auth.settings.find(sett => sett.id === 1)

export const currentUserSelector = (state: TState): userData | null => state.auth.currentUser

export const userFlowSelector = (state: TState) => state.auth.userFlow

export const isAuthSelector = (state: TState) => state.auth.isAuth
