export const getCookie = (name: string): string => {
  let cookieValue = ''
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookiePair = cookies[i].trim()
      if (cookiePair.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookiePair.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export const delete_cookie = (name: string): void => {
  document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
}
export const getLanguage = () => {
  return localStorage.getItem('i18nextLng') || 'en' // ua === default language
}
