import { translateType } from 'translations/types'

interface IValidation {
  phone_number: {
    required: string
    maxLength: {
      value: number
      message: string
    }
    pattern: {
      value: RegExp
      message: string
    }
  }
  email: {
    required: string
    pattern: {
      value: RegExp
      message: string
    }
  }
  password: {
    required: string
    pattern: {
      value: RegExp
      message: string
    }
  }
}

export const getValidation = (t: translateType): IValidation => {
  return {
    email: {
      required: t('formValidation.required'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('formValidation.pattern')
      }
    },
    phone_number: {
      required: t('formValidation.required'),
      maxLength: {
        value: 12,
        message: t('formValidation.maxLength', { n: 12 })
      },
      pattern: {
        value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
        message: t('formValidation.pattern')
      }
    },
    password: {
      required: t('formValidation.required'),
      pattern: {
        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{12,}$/,
        message: t('formValidation.password')
      }
    }
  }
}
