import { TServiceStatus } from '../client/types/stateTypes'
import { IUserFlow, IUserFlowData } from '../lender/types/state'

import authActions from './authActions'
import {
  authActionTypes,
  IHandleLoginError,
  ILogout,
  ISetAppFormUserFlow,
  ISetAuthErrors,
  ISetIsFetching,
  ISetSettings,
  ISetToken,
  ISetUser,
  ISetUserFlowLoadStatus,
  IUpdateAppFormUserFlow
} from './types/actionTypes'
import { errorData, IAuthState, ISettings, userData } from './types/stateTypes'

const authState: IAuthState = {
  isAuth: false,
  isFetching: false,
  errors: null,
  authErrors: null,
  currentUser: null,
  token: '',
  settings: [],
  userFlow: {
    status: 'init',
    appFormFlowData: null
  }
}
const authReducer = (state = authState, action: authActionTypes): IAuthState => {
  switch (action.type) {
    case authActions.LOGOUT:
      return { ...state, isAuth: false, currentUser: null }
    case authActions.SET_USER:
      return {
        ...state,
        isAuth: true,
        currentUser: action.payload
      }
    case authActions.HANDLE_ERROR:
      return {
        ...state,
        errors: action.payload
      }
    case authActions.SET_AUTH_ERRORS: {
      return {
        ...state,
        authErrors: action.payload
      }
    }
    case authActions.SET_IS_FETCHING:
      return { ...state, isFetching: action.payload.isFetching }
    case authActions.SET_TOKEN:
      return { ...state, token: action.payload }
    case authActions.SET_SETTINGS:
      return {
        ...state,
        settings: state.settings.length
          ? [...state.settings].map(set => {
              return set.id === action.payload[0].id ? action.payload[0] : set
            })
          : action.payload
      }
    // for create & delete
    case authActions.SET_APP_FORM_USER_FLOW:
      return {
        ...state,
        userFlow: {
          ...state.userFlow,
          appFormFlowData: action.payload
        }
      }
    // for update
    case authActions.UPDATE_APP_FORM_USER_FLOW:
      if (state.userFlow.appFormFlowData) {
        return {
          ...state,
          userFlow: {
            ...state.userFlow,
            appFormFlowData: {
              ...state.userFlow.appFormFlowData,
              flow_data: {
                ...action.payload
              }
            }
          }
        }
      }
      return state
    case authActions.SET_USER_FLOW_LOAD_STATUS:
      return {
        ...state,
        userFlow: {
          ...state.userFlow,
          status: action.payload
        }
      }
    default:
      return state
  }
}
// actions
export const logout = (): ILogout => ({ type: authActions.LOGOUT })
export const setUser = (data: userData): ISetUser => ({ type: authActions.SET_USER, payload: data })
export const handleLoginError = (error: errorData | null): IHandleLoginError => ({
  type: authActions.HANDLE_ERROR,
  payload: error ?? null
})
export const setAuthErrors = (errors: string[]): ISetAuthErrors => ({
  type: authActions.SET_AUTH_ERRORS,
  payload: errors
})

export const setSetToken = (token: string): ISetToken => ({
  type: authActions.SET_TOKEN,
  payload: token
})
export const setIsFetching = (isFetching: boolean): ISetIsFetching => ({
  type: authActions.SET_IS_FETCHING,
  payload: { isFetching }
})

export const setSettings = (settings: ISettings[]): ISetSettings => ({
  type: authActions.SET_SETTINGS,
  payload: settings
})

export const setAppFormUserFlow = (data: IUserFlow | null): ISetAppFormUserFlow => ({
  type: authActions.SET_APP_FORM_USER_FLOW,
  payload: data
})
export const updateAppFormUserFlow = (data: IUserFlowData): IUpdateAppFormUserFlow => ({
  type: authActions.UPDATE_APP_FORM_USER_FLOW,
  payload: data
})
export const setUserFlowLoadStatus = (status: TServiceStatus): ISetUserFlowLoadStatus => ({
  type: authActions.SET_USER_FLOW_LOAD_STATUS,
  payload: status
})

export default authReducer
