import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import pl from './locales/pl/translation.json'
import ru from './locales/ru/translation.json'

const resources = {
  en: {
    translation: en
  },
  pl: {
    translation: pl
  },
  ru: {
    translation: ru
  }

}
i18next
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    react: {
      useSuspense: false
    }
  })
