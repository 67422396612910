import React, { FC, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import { setInitializedThunk } from 'redux/reducers/app/appReducer'
import { currentUserSelector } from 'redux/reducers/auth/authSelectors'
import { setLanguage } from 'redux/reducers/translation/translationReducer'
import { languageSelector } from 'redux/reducers/translation/translationSelectors'
import { TState } from 'redux/store'

import AppPreloader from './components/common/preloaders/appPreloader'
import ClientRedirectPage from './components/pageClient/applicationForm/redirectPage/redirectPage'
import HomePage from './components/pageHome/homePage'

const LenderPage = lazy(() => import('./components/pageLender/lenderPage'))
const ClientPage = lazy(() => import('./components/pageClient/clientPage'))
const CommercePage = lazy(() => import('./components/pageCommerce/commercePage'))

interface IApp {
  setInitializedThunk: () => any
  setLanguage: (lang: string) => any
  isInit: boolean
}

const App: FC<IApp> = ({ setInitializedThunk, setLanguage, isInit }) => {
  const { t } = useTranslation()

  const currentUser = useSelector(currentUserSelector)
  const currentLanguage = useSelector(languageSelector)

  useEffect(() => {
    setLanguage(currentLanguage)
    setInitializedThunk()
  }, [setLanguage, setInitializedThunk, currentLanguage])

  return (
    <>
      <React.Suspense fallback={<AppPreloader />}>
        {isInit ? (
          <Switch>
            <Route path="/login/:uuid">
              <ClientRedirectPage />
            </Route>
            <Route path="/lender">
              {currentUser?.group === 'Admin' ||
              currentUser?.group === 'Lead' ||
              currentUser?.group === 'Lead:Middle' ||
              currentUser?.group === 'Lead:Soft' ||
              currentUser?.group === 'Lead:Verifier' ||
              currentUser?.group === 'Lender' ||
              currentUser?.group === 'Manager' ||
              currentUser?.group === 'Verifier' ? (
                <LenderPage currentUser={currentUser} />
                  ) : (
                <Redirect to="/login" />
                  )}
            </Route>
            <Route path="/client">
              {currentUser?.group === 'Client' ? <ClientPage /> : <Redirect to="/login" />}
            </Route>
            <Route path="/commerce">
              {currentUser?.group === 'Commerce' ? (
                <CommercePage t={t} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route path="/">
              <HomePage t={t} currentUser={currentUser} />
            </Route>
          </Switch>
        ) : (
          <AppPreloader />
        )}
      </React.Suspense>
    </>
  )
}

const mapStateToProps = (state: TState) => ({
  isInit: state.app.isInitialized
})

export default connect(mapStateToProps, { setLanguage, setInitializedThunk })(App)
