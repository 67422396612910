import { IPaymentGraphic } from 'types/client/ApplicationForm'
import { IToastMessage } from 'types/common/types'

import { IClientCollateralResponse, TCollateralStatuses } from '../lender/types/state'

import { clientActions } from './clientActions'
import {
  actionTypes,
  IClearPaymentApplication,
  IClearPaymentInfo,
  ISetAppFormFetchingStatus,
  ISetAppFormToastMessage,
  ISetApplicationId,
  ISetClientActiveLoansList,
  ISetApplicationStatus,
  ISetApplicationsToBePaid,
  ISetContract,
  ISetGuarantorApplications,
  ISetGuarantorFetchingStatus,
  ISetGuarantorModels,
  ISetPaymentApplication,
  ISetPaymentGraphic,
  ISetPaymentInfo,
  ISetQuestionary,
  ISetShopRequestData,
  ISetSMSStatus,
  ISetUuidToken,
  IUpdateGuarantorModel,
  ISetActiveLoansInitFetchingStatus,
  ISetClientCurrentActiveLoan,
  ISetActiveLoansModalFetchingStatus,
  ISetClientToastMessage,
  ISetAppFormCollaterals,
  ISetAppFormCollateralsStatus,
  ISetInvoice,
  ISetAppFormTemplateType
} from './types/actionTypes'
import {
  clientState,
  IApplication,
  IGuarantorModel,
  IPaymentInfo,
  IShopRequestData,
  TServiceStatus
} from './types/stateTypes'

const initialState: clientState = {
  applicationForm: {
    serviceStatus: 'init',
    status: 0,
    shopRequestData: null,
    paymentGraphic: null,
    currentQuestionary: null,
    collaterals: {
      status: 'init',
      list: []
    },
    uuidToken: '',
    contractUrl: null,
    invoiceBlob: null,
    applicationId: null,
    loanTerm: null,
    SMSStatus: null,
    templateType: '',
    toastMessage: null
  },
  activeLoans: {
    initFetchingStatus: true,
    fetchingStatus: false,
    modalFetchingStatus: false,
    list: [],
    current: null
  },
  guarantor: {
    applications: {
      list: []
    },
    models: {
      list: []
    },
    fetchingStatus: true
  },
  applicationsToBePaid: {
    list: [],
    current: null
  },
  paymentInfo: null,
  toastMessage: null
}
const clientReducer = (state = initialState, action: actionTypes): clientState => {
  switch (action.type) {
    case clientActions.SET_UUID_TOKEN: {
      return { ...state, applicationForm: { ...state.applicationForm, uuidToken: action.payload } }
    }
    case clientActions.SET_PAYMENT_GRAPHIC: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, paymentGraphic: action.payload }
      }
    }
    case clientActions.SET_QUESTIONARY: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, currentQuestionary: action.payload }
      }
    }
    case clientActions.SET_SHOP_REQUEST_DATA: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, shopRequestData: action.payload }
      }
    }
    case clientActions.SET_CONTRACT: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, contractUrl: action.payload }
      }
    }
    case clientActions.SET_INVOICE: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, invoiceBlob: action.payload }
      }
    }
    case clientActions.SET_APP_FORM_TEMPLATE_TYPE: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, templateType: action.payload }
      }
    }
    // <> active loans
    case clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          initFetchingStatus: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_ACTIVE_LOANS_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          list: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_CURRENT_ACTIVE_LOAN: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          current: action.payload
        }
      }
    }
    case clientActions.SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          modalFetchingStatus: action.payload
        }
      }
    }
    // </> active loans
    case clientActions.SET_APPLICATIONS_TO_BE_PAID: {
      const applications = action.payload.filter(({ status }) => status.id === 6 || status.id === 8)

      return {
        ...state,
        applicationsToBePaid: { ...state.applicationsToBePaid, list: applications }
      }
    }
    case clientActions.SET_APPLICATION_ID: {
      localStorage.setItem('applicationId', action.payload.toString())
      return {
        ...state,
        applicationForm: { ...state.applicationForm, applicationId: action.payload }
      }
    }
    case clientActions.SET_APPLICATION_STATUS: {
      localStorage.setItem('applicationStatus', action.payload.toString())
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          status: action.payload
        }
      }
    }
    case clientActions.SET_APP_FORM_FETCHING_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          serviceStatus: action.payload
        }
      }
    }
    case clientActions.SET_APP_FORM_COLLATERALS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          collaterals: {
            ...state.applicationForm.collaterals,
            list: action.payload
          }
        }
      }
    }
    case clientActions.SET_APP_FORM_COLLATERALS_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          collaterals: {
            ...state.applicationForm.collaterals,
            status: action.payload
          }
        }
      }
    }
    case clientActions.SET_APP_FORM_TOAST_MESSAGE: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          toastMessage: action.payload
        }
      }
    }

    case clientActions.SET_SMS_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          SMSStatus: action.payload
        }
      }
    }

    case clientActions.SET_PAYMENT_APPLICATION: {
      return {
        ...state,
        applicationsToBePaid: { ...state.applicationsToBePaid, current: action.payload }
      }
    }
    case clientActions.SET_PAYMENT_INFO: {
      return { ...state, paymentInfo: action.payload }
    }

    case clientActions.CLEAR_PAYMENT_APPLICATION: {
      return { ...state, applicationsToBePaid: { ...state.applicationsToBePaid, current: null } }
    }
    // eslint-disable-next-line no-duplicate-case
    case clientActions.CLEAR_PAYMENT_APPLICATION: {
      return { ...state, paymentInfo: null }
    }
    // guarantor
    case clientActions.SET_GUARANTOR_APPLICATIONS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          applications: {
            ...state.guarantor.applications,
            list: action.payload
          }
        }
      }
    }
    case clientActions.SET_GUARANTOR_MODELS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          models: {
            ...state.guarantor.models,
            list: action.payload
          }
        }
      }
    }
    case clientActions.UPDATE_GUARANTOR_MODEL: {
      const updatedList = state.guarantor.models.list.map(model =>
        model.uuid === action.payload.uuid ? action.payload : model
      )

      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          models: {
            ...state.guarantor.models,
            list: updatedList
          }
        }
      }
    }
    case clientActions.SET_GUARANTOR_FETCHING_STATUS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          fetchingStatus: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload
      }
    }
    default:
      return state
  }
}
// actions
export const setUuidToken = (token: string): ISetUuidToken => ({
  type: clientActions.SET_UUID_TOKEN,
  payload: token
})
export const setPaymentGraphic = (data: IPaymentGraphic[]): ISetPaymentGraphic => ({
  type: clientActions.SET_PAYMENT_GRAPHIC,
  payload: data
})
export const setCurrentQuestionary = (data: any): ISetQuestionary => ({
  type: clientActions.SET_QUESTIONARY,
  payload: data
})
export const setShopRequestData = (data: IShopRequestData): ISetShopRequestData => ({
  type: clientActions.SET_SHOP_REQUEST_DATA,
  payload: data
})
export const setContract = (data: Blob): ISetContract => ({
  type: clientActions.SET_CONTRACT,
  payload: data
})
export const setInvoice = (data: Blob | null): ISetInvoice => ({
  type: clientActions.SET_INVOICE,
  payload: data
})
export const setAppFormTemplateType = (data: string): ISetAppFormTemplateType => ({
  type: clientActions.SET_APP_FORM_TEMPLATE_TYPE,
  payload: data
})

// <> active loans
export const setActiveLoansInitFetchungStatus = (
  status: boolean
): ISetActiveLoansInitFetchingStatus => ({
  type: clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS,
  payload: status
})
export const setClientActiveLoansList = (data: IApplication[]): ISetClientActiveLoansList => ({
  type: clientActions.SET_CLIENT_ACTIVE_LOANS_LIST,
  payload: data
})
export const setClientCurrentActiveLoan = (
  data: IApplication | null
): ISetClientCurrentActiveLoan => ({
  type: clientActions.SET_CLIENT_CURRENT_ACTIVE_LOAN,
  payload: data
})
export const setActiveLoansModalFetchingStatus = (
  status: boolean
): ISetActiveLoansModalFetchingStatus => ({
  type: clientActions.SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS,
  payload: status
})
// </> active loans

export const setApplicationId = (id: number): ISetApplicationId => ({
  type: clientActions.SET_APPLICATION_ID,
  payload: id
})
export const SetApplicationStatus = (status: number): ISetApplicationStatus => ({
  type: clientActions.SET_APPLICATION_STATUS,
  payload: status
})
export const setAppFormFetchingStatus = (status: TServiceStatus): ISetAppFormFetchingStatus => ({
  type: clientActions.SET_APP_FORM_FETCHING_STATUS,
  payload: status
})
export const setAppFormCollaterals = (
  collaterals: IClientCollateralResponse[]
): ISetAppFormCollaterals => ({
  type: clientActions.SET_APP_FORM_COLLATERALS,
  payload: collaterals
})
export const setAppFormCollateralsStatus = (
  status: TCollateralStatuses
): ISetAppFormCollateralsStatus => ({
  type: clientActions.SET_APP_FORM_COLLATERALS_STATUS,
  payload: status
})
export const setAppFormToastMessage = (message: IToastMessage | null): ISetAppFormToastMessage => ({
  type: clientActions.SET_APP_FORM_TOAST_MESSAGE,
  payload: message
})
export const SetSMSStatus = (status: string): ISetSMSStatus => ({
  type: clientActions.SET_SMS_STATUS,
  payload: status
})
export const setApplicationsToBePaid = (data: IApplication[]): ISetApplicationsToBePaid => ({
  type: clientActions.SET_APPLICATIONS_TO_BE_PAID,
  payload: data
})

export const setPaymentApplication = (data: IApplication): ISetPaymentApplication => ({
  type: clientActions.SET_PAYMENT_APPLICATION,
  payload: data
})
export const setPaymentInfo = (data: IPaymentInfo): ISetPaymentInfo => ({
  type: clientActions.SET_PAYMENT_INFO,
  payload: data
})

export const clearPaymentApplication = (): IClearPaymentApplication => ({
  type: clientActions.CLEAR_PAYMENT_APPLICATION
})
export const clearPaymentInfo = (): IClearPaymentInfo => ({
  type: clientActions.CLEAR_PAYMENT_INFO
})

// guarantor
export const setGuarantorApplications = (
  applications: IApplication[]
): ISetGuarantorApplications => ({
  type: clientActions.SET_GUARANTOR_APPLICATIONS,
  payload: applications
})

export const setGuarantorModels = (applications: IGuarantorModel[]): ISetGuarantorModels => ({
  type: clientActions.SET_GUARANTOR_MODELS,
  payload: applications
})

export const updateGuarantorModel = (model: IGuarantorModel): IUpdateGuarantorModel => ({
  type: clientActions.UPDATE_GUARANTOR_MODEL,
  payload: model
})

export const setGuarantorFetchingStatus = (status: boolean): ISetGuarantorFetchingStatus => ({
  type: clientActions.SET_GUARANTOR_FETCHING_STATUS,
  payload: status
})

export const setClientToastMessage = (
  toastMessage: IToastMessage | null
): ISetClientToastMessage => ({
  type: clientActions.SET_CLIENT_TOAST_MESSAGE,
  payload: toastMessage
})

export default clientReducer
