import { AxiosResponse } from 'axios'

import { axiosInstancePrivate } from 'api/api'
import {
  IApplication,
  IAppOrderInfo,
  IGuarantor,
  IGuarantorModel,
  IQuestionaryResponse,
  IShopRequestData,
  TGuarantorModelStatus
} from 'redux/reducers/client/types/stateTypes'
import {
  IClientCollateral,
  IClientCollateralResponse,
  IUserFlow,
  IUserFlowData
} from 'redux/reducers/lender/types/state'
import { IPaymentGraphic } from 'types/client/ApplicationForm'
import { getCookie } from 'utils/common/apiHelpers'

export const clientApi = {
  applicationForm: {
    getShopRequest: (uuid: string): Promise<AxiosResponse<IShopRequestData>> =>
      axiosInstancePrivate.get(`api_integration/shop_request/${uuid}/`),
    getPaymentGraphic: (uuid: string): Promise<AxiosResponse<IPaymentGraphic[]>> => {
      return axiosInstancePrivate(`api_integration/payment_graphic/?shop_request_id=${uuid}`).catch(
        err => err.response
      )
    },
    getManualVerification: () => {
      return axiosInstancePrivate('lender/credit_policies').catch(err => err.response)
    },
    getManualVerificationById: (id: number) => {
      return axiosInstancePrivate(`lender/credit_policies/${id}`).catch(err => err.response)
    },
    getCurrentQuestionary: (policyId: number) => {
      return axiosInstancePrivate(`lender/credit_policies/${policyId}/questionaries/`).catch(
        err => err.response
      )
    },
    sendQuestionaryFormFirstRequest: (
      policyId: number,
      questionaryId: number,
      data: any
    ): Promise<AxiosResponse<IQuestionaryResponse>> =>
      axiosInstancePrivate.post(
        `lender/credit_policies/${policyId}/questionaries/${questionaryId}/completed_questionaries/`,
        { json_data: { ...data } }
      ),
    sendRecurringPayment: (application_id: number) => {
      return axiosInstancePrivate.patch(`/client/applications/${application_id}/`, {
        recurring_payment: true
      })
    },
    postApplication: (data: any): Promise<AxiosResponse<{ id: number }>> =>
      axiosInstancePrivate.post('client/applications/', {
        ...data,
        checks: {
          proxy_check: false
        }
      }),
    sendDataCard: (
      user_id: number,
      application_id: number,
      card_number: string,
      cardholder_name: string
    ) => {
      return axiosInstancePrivate
        .post(`/client/${user_id}/bank_card/`, {
          application_id,
          card_number,
          cardholder_name
        })
        .catch(err => err.response)
    },
    changeStatus: (id: number, status: number) => {
      return axiosInstancePrivate
        .patch(`/client/applications/${id}/`, {
          status
        })
        .catch(err => console.error(err))
    },
    getContract: (contract_id: string | number) => {
      return axiosInstancePrivate(`client/contracts/${contract_id}`, {
        headers: {
          Authorization: `Token ${getCookie('token')}`
        },
        responseType: 'blob'
      }).catch(err => err.response)
    },
    getClientContractByAppId: (appId: number): Promise<AxiosResponse<Blob>> =>
      axiosInstancePrivate.get(`client/contracts/by_application_id/${appId}/`, {
        responseType: 'blob'
      }),
    getClientOrderInfoByAppId: (appId: number): Promise<AxiosResponse<IAppOrderInfo>> =>
      axiosInstancePrivate.get(`client/applications/${appId}/orderinfo/`),
    getClientInvoice: (appId: number): Promise<AxiosResponse<IAppOrderInfo>> =>
      axiosInstancePrivate.get(`client/applications/${appId}/orderinfo/`),
    getSMSCode: (id: number) => {
      return axiosInstancePrivate
        .post('smsclub/send_sms/', { application_id: id })
        .catch(err => err.response)
    },
    checkSMSCode: (application_id: number, sms_code: string) => {
      return axiosInstancePrivate
        .post('smsclub/check_sms/', { application_id, sms_code })
        .catch(err => err.response)
    },
    completeForm: (application_id: number, status: number): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${application_id}/`, { status }),
    cancelApplication: (application_id: number) => {
      return axiosInstancePrivate
        .patch(`client/applications/${application_id}/`, { status: 10 })
        .catch(err => err.response)
    },
    getClientCollateral: (appId: number): Promise<AxiosResponse<IClientCollateralResponse[]>> =>
      axiosInstancePrivate.get(`client/applications/${appId}/collaterals/`),
    postClientCollateral: (
      appId: number,
      data: IClientCollateral
    ): Promise<AxiosResponse<IClientCollateralResponse>> =>
      axiosInstancePrivate.post(`client/applications/${appId}/collaterals/`, data)
  },
  applications: {
    getApplications: () => axiosInstancePrivate('client/applications/').catch(err => err.response),

    patchApplicationById: (
      applicationId: number,
      type: string,
      data: any
    ): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${applicationId}/`, { [type]: data }),

    patchApplication: (
      id: number,
      data: any
    ): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${id}/`, data),

    getApplication: (id: number): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate(`client/applications/${id}`).catch(err => err.response),

    getApplicationsStatistics: (query: string) =>
      axiosInstancePrivate(`api/client/applications/statistic/?${query}`),

    postUserFlowData: (data: IUserFlowData): Promise<AxiosResponse<IUserFlow>> =>
      axiosInstancePrivate.post('users/user_flow/', { flow_data: data }),

    patchUserFlowData: (id: number, flow_data: IUserFlowData): Promise<AxiosResponse<IUserFlow>> =>
      axiosInstancePrivate.patch(`users/user_flow/${id}/`, { flow_data }),
    deleteUserFlowData: (id: number): Promise<AxiosResponse> =>
      axiosInstancePrivate.delete(`users/user_flow/${id}/`)
  },
  guarantor: {
    postRegistery: (data: IGuarantor, uuid: string): Promise<AxiosResponse<IGuarantor>> =>
      axiosInstancePrivate.post(`users/guarantors/${uuid}/register/`, data),

    getApplications: (): Promise<AxiosResponse<IApplication[]>> =>
      axiosInstancePrivate.get('client/applications/guarantor_applications/'),

    getModels: (): Promise<AxiosResponse<IGuarantorModel[]>> =>
      axiosInstancePrivate.get('client/guarantors/'),

    getContractByModelUuid: (modelUuid: string): Promise<AxiosResponse<Blob>> =>
      axiosInstancePrivate.get(`client/contracts/guarantor/by_guarantor_id/${modelUuid}/`, {
        responseType: 'blob'
      }),

    postModel: (data: IGuarantorModel): Promise<AxiosResponse<IGuarantorModel>> =>
      axiosInstancePrivate.post('client/guarantors/', data),

    getSMSCode: (uuid: string): Promise<AxiosResponse<string>> =>
      axiosInstancePrivate.post('smsclub/send_guarantor_sms/', { guarantor_id: uuid }),

    checkSMSCode: (uuid: string, sms_code: string): Promise<AxiosResponse> =>
      axiosInstancePrivate.post('smsclub/check_guarantor_sms/', { guarantor_id: uuid, sms_code }),

    patchModelStatus: (
      uuid: string,
      status: TGuarantorModelStatus
    ): Promise<AxiosResponse<IGuarantorModel>> =>
      axiosInstancePrivate.patch(`client/guarantors/${uuid}/`, { status })
  }
}
