import axios from 'axios'

import { API_URL } from 'config'
import { getCookie, getLanguage } from 'utils/common/apiHelpers'

const axiosInstance = axios.create()
const axiosInstancePrivate = axios.create()

axiosInstance.interceptors.request.use(
  function (config) {
    config.baseURL = API_URL

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosInstancePrivate.interceptors.request.use(
  function (config) {
    if (config.headers !== undefined) {
      const language = getLanguage()
      const token = getCookie('token')

      if (language) {
        config.headers['Accept-Language'] = language
      }
      if (token) {
        config.headers.Authorization = `Token ${token}`
      }
    }
    config.baseURL = API_URL

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { axiosInstance, axiosInstancePrivate }
