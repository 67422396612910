import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: ['Raleway', 'Roboto', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h3: {
      fontSize: '1.75rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1200,
      xl: 1440
    }
  },
  palette: {
    primary: {
      /* light: '#4fb953', */
      main: '#545AF5'
      /*  dark: '#2e7d32', */
      /*  contrastText: '#fff' */
    },
    error: {
      main: '#F46B6B',
      contrastText: '#fff'
    }
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        text: {
          dominantBaseline: 'middle'
        }
      }
    }
  }
})
export default theme
