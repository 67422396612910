import { IToastMessage } from 'types/common/types'

import { commerceActions } from './commerceActions'
import {
  ISetFetchingCommerceData,
  ISetCommerceShops,
  ISetCommerceToastMessage,
  ISetGeneratedProductLink,
  ISetInvitationResults,
  ISetInvitationsCount,
  ISetInviteMemberDialog,
  ISetSelectedShop,
  ISetShopCategories,
  TCommerceActions,
  ISetInvoiceModalStatus,
  ISetInvoiceStatus,
  ISetInvoiceApplicationId
} from './types/actionTypes'
import {
  ICommerceApplications,
  ICommerceShop,
  ICommerceShops,
  ICommerceState,
  IInvitationResults,
  IShopCategories,
  TInvoiceStatuses
} from './types/stateTypes'

const commerceState: ICommerceState = {
  isFetching: false,
  applications: [],
  applicationsCount: null,
  shopCategories: [],
  shops: [],
  selectedShop: null,
  invitationsCount: null,
  invitationStats: null,
  productLinkGenerator: null,
  inviteMemberDialog: {
    isOpen: false
  },
  invoice: {
    status: 'init',
    applicationId: null,
    isModalShow: false
  },
  toastMessage: null
}

const commerceReducer = (state = commerceState, action: TCommerceActions): ICommerceState => {
  switch (action.type) {
    case commerceActions.SET_FETCHING_COMMERCE_DATA: {
      return { ...state, isFetching: action.payload }
    }

    case commerceActions.GET_APPLICATIONS_COUNT: {
      return { ...state, applicationsCount: action.payload }
    }

    case commerceActions.GET_APPLICATIONS: {
      return { ...state, applications: action.payload }
    }

    case commerceActions.SET_SHOP_CATEGORIES: {
      return { ...state, shopCategories: action.payload }
    }
    case commerceActions.SET_SHOPS: {
      return { ...state, shops: action.payload }
    }
    case commerceActions.SET_PRODUCT_LINK: {
      return {
        ...state,
        productLinkGenerator: { ...state.productLinkGenerator, link: action.payload }
      }
    }
    case commerceActions.SET_SELECTED_SHOP: {
      return { ...state, selectedShop: action.payload }
    }
    case commerceActions.SET_INVITATIONS_COUNT: {
      return { ...state, invitationsCount: action.payload }
    }
    case commerceActions.SET_INVITATION_STATS: {
      return { ...state, invitationStats: { ...action.payload } }
    }
    case commerceActions.SET_INVITE_MEMBER_DIALOG: {
      return {
        ...state,
        inviteMemberDialog: { ...state.inviteMemberDialog, isOpen: action.payload }
      }
    }
    case commerceActions.SET_INVOICE_MODAL_STATUS: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          isModalShow: action.payload
        }
      }
    }
    case commerceActions.SET_INVOICE_STATUS: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          status: action.payload
        }
      }
    }
    case commerceActions.SET_INVOICE_APPLICATION_ID: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          applicationId: action.payload
        }
      }
    }
    case commerceActions.SET_COMMERCE_TOAST_MESSAGE: {
      return { ...state, toastMessage: action.payload }
    }

    default: {
      return state
    }
  }
}
export default commerceReducer

// actionCreators
export const setFetchingCommerceData = (isFetching: boolean): ISetFetchingCommerceData => ({
  type: commerceActions.SET_FETCHING_COMMERCE_DATA,
  payload: isFetching
})

export const getApplicationsCount = (applicationsCount: number) => ({
  type: commerceActions.GET_APPLICATIONS_COUNT,
  payload: applicationsCount
})

export const getApplications = (applications: ICommerceApplications) => ({
  type: commerceActions.GET_APPLICATIONS,
  payload: applications
})

export const setCommerceShops = (shops: ICommerceShops): ISetCommerceShops => ({
  type: commerceActions.SET_SHOPS,
  payload: shops
})
export const setShopCategories = (categories: IShopCategories[]): ISetShopCategories => ({
  type: commerceActions.SET_SHOP_CATEGORIES,
  payload: categories
})

export const setGeneratedProductLink = (link: string): ISetGeneratedProductLink => ({
  type: commerceActions.SET_PRODUCT_LINK,
  payload: link
})

export const setSelectedShop = (shop: ICommerceShop): ISetSelectedShop => ({
  type: commerceActions.SET_SELECTED_SHOP,
  payload: shop
})

export const setInvitationsCount = (invitationsCount: number): ISetInvitationsCount => ({
  type: commerceActions.SET_INVITATIONS_COUNT,
  payload: invitationsCount
})

export const setInvitationsStatistic = (statistic: IInvitationResults): ISetInvitationResults => ({
  type: commerceActions.SET_INVITATION_STATS,
  payload: statistic
})

export const setInviteMemberDialog = (isOpen: boolean): ISetInviteMemberDialog => ({
  type: commerceActions.SET_INVITE_MEMBER_DIALOG,
  payload: isOpen
})

export const setInvoiceModalStatus = (status: boolean): ISetInvoiceModalStatus => ({
  type: commerceActions.SET_INVOICE_MODAL_STATUS,
  payload: status
})
export const setInvoiceStatus = (status: TInvoiceStatuses): ISetInvoiceStatus => ({
  type: commerceActions.SET_INVOICE_STATUS,
  payload: status
})
export const setInvoiceApplicationId = (id: number | null): ISetInvoiceApplicationId => ({
  type: commerceActions.SET_INVOICE_APPLICATION_ID,
  payload: id
})

export const setCommerceToastMessage = (
  toastMessage: IToastMessage | null
): ISetCommerceToastMessage => ({
  type: commerceActions.SET_COMMERCE_TOAST_MESSAGE,
  payload: toastMessage
})
