import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import IconFacebook from 'assets/images/icons/facebook-icon.svg'
import styles from 'components/pageHome/loginPage/FacebookSignInButton/FacebookSignInButton.module.scss'
import { FACEBOOK_ID } from 'config'
import { facebookSignInThunk } from 'redux/reducers/auth/authThunks'
import { loadScript } from 'utils/loadScript'

declare const window: any
let FB: any

interface IOAuthResponse {
  authResponse: { accessToken: string }
  status: string
}

export const FacebookSignInButton: React.FC = () => {
  const dispatch = useDispatch()

  const handleCredentialResponse = useCallback(() => {
    FB = window.FB

    FB.getLoginStatus((response: IOAuthResponse) => {
      if (response.status === 'connected') {
        dispatch(facebookSignInThunk(response.authResponse.accessToken))
      } else {
        FB.login((response: IOAuthResponse) => dispatch(facebookSignInThunk(response.authResponse.accessToken)), { scope: 'email' })
      }
    })
  }, [])

  useEffect(() => {
    FB = window.FB
    const src = `https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v15.0&appId=${FACEBOOK_ID}`

    loadScript(src).then(() => {
      FB.fbAsyncInit = () => {
        FB.init({
          appId: FACEBOOK_ID,
          cookie: true,
          xfbml: true
        })
      }

      return () => {
        const script = document.querySelector(`script[src="${src}"]`)
        if (script) document.body.removeChild(script)
      }
    })
  }, [])

  return <div
      className={styles.facebookBtn}
      role="button"
      tabIndex={0}
      onClick={handleCredentialResponse}
    >
      <img src={IconFacebook} alt="Facebook icon" className={styles.icon}/>
      <span className={styles.title}>Facebook</span>
    </div>
}
